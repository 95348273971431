import React, { createElement, ReactNode, Fragment } from 'react';
import RehypeReact from 'rehype-react';

import type { HastComponentConfig, HastNode } from './types';
import { Link, Paragraph } from '@Components';

export const hastToReact = (
  hastNode?: HastNode,
  components: HastComponentConfig = {},
): ReactNode => (hastNode
  ? new RehypeReact({
    createElement,
    Fragment,
    components: {
      p: Paragraph,
      a: Link,
      ...Object.keys(components).reduce((prev, key) => {
        const { Component, Props = {} } = components[key];

        return {
          ...prev,
          [key]: (props: any): ReactNode => (
            <Component
              {...props}
              {...Props}
            />
          ),
        };
      }, {}),
    },
  }).Compiler(hastNode)
  : '');
